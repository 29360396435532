<template>
  <div class="tw-container border border-1 border-dark p-lg-3 p-0">
    <!-- 標題 -->
    <p class="tw-border-start fw-bolder tw-text-size20 m-lg-0 mt-3 ms-3">
      個人賣場
    </p>
    <hr />
    <!-- 內容 -->
    <div class="row">
      <!-- 左 -->
      <div class="col-lg-5 p-0">
        <!-- 賣場網址 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <div class="mb-2">
            <span class="fw-bolder p-1 me-2">賣場網址</span>
            <button
              class="tw-btn tw-btn-secondary"
              @click="goOtherPage(`/customer/store/${storeId}/shop`)"
            >
              前往賣場
            </button>
          </div>
          <!-- 原賣場網址 -->
          <div class="row align-items-center mb-1">
            <div class="tw-container border border-1 border-dark m-0 col-9">
              <template v-if="!showShortUrl">
                原網址連結：
                <span class="d-inline-block" style="word-break: break-all">
                  {{ `${storeUrl}customer/store/${storeId}/shop` }}
                </span>
              </template>
              <template v-else>
                短網址連結：
                <span class="d-inline-block" style="word-break: break-all">
                  {{ storeUrl + "s/" + storeInfo.shortCut }}
                </span>
              </template>
            </div>
            <div class="col-3">
              <button
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="copy(false)"
              >
                複製
              </button>
              <button
                class="tw-btn tw-btn-warning"
                v-if="!showShortUrl"
                @click="changeUrl"
              >
                短網址
              </button>
              <button class="tw-btn tw-btn-secondary" v-else @click="changeUrl">
                原網址
              </button>
            </div>
          </div>
          <!-- fb 授權 -->
          <div class="row align-items-center">
            <div class="tw-container border border-1 border-dark m-0 col-9">
              fb 授權:
              <span class="d-inline-block" style="word-break: break-all">
                {{ `${storeUrl}authFb/${storeId}` }}
              </span>
            </div>
            <div class="col-3">
              <button
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="copy(`${storeUrl}authFb/${storeId}`)"
              >
                複製
              </button>
            </div>
          </div>
        </div>
        <!-- FB免註冊連結： -->
        <!-- <div class="tw-container border border-1 border-dark mb-3">
          <div class="mb-2">
            <span class="fw-bolder text-danger p-1 me-2"
              ><i class="bi bi-award-fill text-warning"></i>FB免註冊連結
            </span>
          </div>
          <div class="row align-items-center">
            <div class="tw-container border border-1 border-dark m-0 col-9">
              FB免註冊連結：
              <span class="d-inline-block" style="word-break: break-all">
                {{ `${storeUrl}authFb` }}
              </span>
            </div>
            <div class="col-3">
              <button
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="copy(`${storeUrl}authFb`)"
              >
                複製
              </button>
            </div>
          </div>
        </div> -->
        <!-- 賣場介紹 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <div class="mb-2">
            <span class="fw-bolder p-1 me-2">賣場介紹</span>
            <button class="tw-btn tw-btn-success" @click="updateIntroduction">
              更改
            </button>
          </div>
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder=""
              rows="3"
              id="StoreIntroduction"
              style="height: 100px"
              maxlength="500"
              v-model="storeInfo.introduction"
            ></textarea>
            <label for="StoreIntroduction">輸入賣場介紹(500字)</label>
          </div>
        </div>
        <!-- 賣場商品小圖價格呈現方式 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1">賣場商品小卡</p>
          <AreaLoading v-if="p_loading"></AreaLoading>
          <div class="shop" v-else>
            <!-- 卡片 -->
            <div class="merh-area">
              <div class="merh-card-area">
                <!-- 商品卡片 -->
                <div
                  class="merch-card mb-3 p-2 m-auto"
                  style="max-width: 250px; width: 100%"
                >
                  <div class="deadline-area fw-bolder text-break">
                    <CountDown
                      :id="1"
                      :time="$methods.moment().add(10, 'days').valueOf()"
                    ></CountDown>
                  </div>
                  <!-- 圖 -->
                  <img
                    class="merch-img mb-2"
                    src="@/assets/other-images/noImg.png"
                  />
                  <!-- 分隔線 -->
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <hr class="m-0 p-0 w-100" />
                    <!-- 販售狀態 -->
                    <div class="fw-bolder text-center d-inline-block">
                      <p
                        class="text-success alert-success p-1"
                        style="width: 60px"
                      >
                        可預購
                      </p>
                    </div>
                  </div>
                  <!-- 內容 -->
                  <div>
                    <ul>
                      <!-- 商品名 -->
                      <li class="fw-bolder tw-text-size18 mb-1 merch-name">
                        商品名稱
                      </li>
                      <!-- 商品標籤 -->
                      <li class="mb-2 text-success fw-bolder merch-lable">
                        標籤
                      </li>
                      <!-- 售價 -->
                      <li class="mb-2">
                        <span v-if="merchCard === 'normal'"> $50 ~ $100 </span>
                        <span v-else>
                          <del class="me-2">$100</del>
                          <span
                            class="fw-bolder text-danger"
                            style="font-size: 17px"
                            >$50</span
                          >
                        </span>
                      </li>
                      <!-- 成團數 / 庫存 -->
                      <li class="d-flex fw-bolder mb-2">
                        <span
                          class="text-danger border border-danger me-2"
                          style="padding: 2px"
                          >50單成團</span
                        >
                        <span
                          class="text-primary border border-primary"
                          v-if="showStock"
                          >庫存: 50</span
                        >
                      </li>
                    </ul>
                    <!-- 最底下資訊 -->
                    <div class="info-area tw-text-size14 text-secondary">
                      <!-- 熱賣 -->
                      <p class="fw-bolder" v-if="sellQuantity !== 'doNotShow'">
                        售出:
                        <span v-if="sellQuantity === '1'">2</span>
                        <span v-else-if="sellQuantity === '10'">12</span>
                        <span v-else-if="sellQuantity === '30'">32</span>
                        <span v-else-if="sellQuantity === '50'">52</span>
                        <span v-else-if="sellQuantity === '100'">102</span>
                      </p>
                      <!-- 到貨時間 -->
                      <p
                        class="text-break d-inline-block fw-bolder"
                        v-if="showArrivalTime"
                      >
                        到貨:{{
                          $methods.moment().format("YYYY-MM-DD HH:mm:ss")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 價格呈現方式 -->
            <div class="tw-container border border-1 border-dark m-0 mb-2">
              <p class="fw-bolder p-1 me-2 mb-2">商品小卡價格呈現方式</p>
              <div
                class="form-check mb-2"
                @click="updateConfiguration('merchCard', 'normal')"
              >
                <input
                  class="form-check-input me-2"
                  type="radio"
                  value="normal"
                  id="StoreCardShow1"
                  name="StoreCardShow"
                  v-model="merchCard"
                />
                <label class="form-check-label" for="StoreCardShow1">
                  一般價格呈現模式(不會顯示多件優惠的價格)
                </label>
              </div>
              <div
                class="form-check"
                @click="
                  updateConfiguration(
                    'merchCard',
                    'accordingToCustomerShowDiscount'
                  )
                "
              >
                <input
                  class="form-check-input mb-2 me-2"
                  type="radio"
                  value="accordingToCustomerShowDiscount"
                  id="StoreCardShow2"
                  name="StoreCardShow"
                  v-model="merchCard"
                />
                <label class="form-check-label" for="StoreCardShow2">
                  顯示多件優惠的最低價格(依照顧客身分的條件顯示最低價格)
                </label>
              </div>
            </div>
            <!-- 顯示庫存 -->
            <div class="tw-container border border-1 border-dark m-0 mb-2">
              <p class="fw-bolder p-1 me-2 mb-2">商品小卡顯示庫存</p>
              <!-- 選項 -->
              <div>
                <div
                  class="form-check d-inline-block me-2 p-0"
                  @click="updateConfiguration('showStock', false)"
                >
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    :value="false"
                    id="ShowStock1"
                    name="ShowStock"
                    v-model="showStock"
                  />
                  <label class="form-check-label" for="ShowStock1">
                    不允許
                  </label>
                </div>
                <div
                  class="form-check d-inline-block me-2 p-0"
                  @click="updateConfiguration('showStock', true)"
                >
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    :value="true"
                    id="ShowStock2"
                    name="ShowStock"
                    v-model="showStock"
                  />
                  <label class="form-check-label" for="ShowStock2">
                    允許
                  </label>
                </div>
              </div>
            </div>
            <!-- 顯示商品到貨時間 -->
            <div class="tw-container border border-1 border-dark m-0 mb-2">
              <p class="fw-bolder p-1 me-2 mb-2">商品小卡到貨時間</p>
              <!-- 選項 -->
              <div>
                <div
                  class="form-check d-inline-block me-2 p-0"
                  @click="updateConfiguration('showArrivalTime', false)"
                >
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    :value="false"
                    id="ShowArrivalTime1"
                    name="ShowArrivalTime"
                    v-model="showArrivalTime"
                  />
                  <label class="form-check-label" for="ShowArrivalTime1">
                    不允許
                  </label>
                </div>
                <div
                  class="form-check d-inline-block me-2 p-0"
                  @click="updateConfiguration('showArrivalTime', true)"
                >
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    :value="true"
                    id="ShowArrivalTime2"
                    name="ShowArrivalTime"
                    v-model="showArrivalTime"
                  />
                  <label class="form-check-label" for="ShowArrivalTime2">
                    允許
                  </label>
                </div>
              </div>
            </div>
            <!-- 顯示販售數量資訊 -->
            <div class="tw-container border border-1 border-dark m-0">
              <p class="fw-bolder p-1 me-2 mb-2">商品小卡顯示販售數量</p>
              <select class="form-select" v-model="sellQuantity">
                <option value="doNotShow">不顯示(預設)</option>
                <option value="1">售出1個以上就顯示</option>
                <option value="10">售出10個以上就顯示</option>
                <option value="30">售出30個以上就顯示</option>
                <option value="50">售出50個以上就顯示</option>
                <option value="100">售出100個以上就顯示</option>
              </select>
            </div>
          </div>
        </div>
        <!-- 個人賣場VIP價格呈現方式 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1 me-2 mb-2">個人賣場多件優惠價格呈現方式</p>
          <AreaLoading v-if="p_loading"></AreaLoading>
          <template v-else>
            <!-- 展示 -->
            <table class="table table-hover">
              <thead>
                <tr class="text-start">
                  <th colspan="3">主要款式</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-start">
                  <td colspan="3">
                    <div class="select-radio">
                      <label>
                        <input type="radio" name="mainStyle" />
                        <span class="radio-style" style="background: #f7f77c"
                          >XS</span
                        >
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="fw-bolder">
                  <td>優惠數量</td>
                  <td>優惠價格</td>
                  <td>資格限制</td>
                </tr>
                <tr>
                  <td>1~5</td>
                  <td>100元</td>
                  <td></td>
                </tr>
                <tr v-if="orderPage === 'underMyVip' || orderPage === 'all'">
                  <td>1~5</td>
                  <td>90元</td>
                  <td class="text-danger">VIP1以上可購買</td>
                </tr>
                <tr>
                  <td>1~5</td>
                  <td>80元</td>
                  <td class="text-danger">VIP2以上可購買</td>
                </tr>
                <tr v-if="orderPage === 'all'">
                  <td>1~5</td>
                  <td>70元</td>
                  <td class="text-danger">VIP3以上可購買</td>
                </tr>
              </tbody>
            </table>
            <!-- 選項 -->
            <p class="mb-2">
              優惠價只給該VIP等級的會員，如果選擇的呈現方式是也可給其他等級的會員看到，他們只能看到價格，但無法享有該會員價格。
            </p>
            <!-- 只看到自己等級以下的單價(預設) -->
            <div
              class="form-check me-2 p-0"
              @click="updateConfiguration('orderPage', 'underMyVip')"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                value="underMyVip"
                id="VipShow1"
                name="VipShow"
                v-model="orderPage"
              />
              <label class="form-check-label" for="VipShow1">
                只看到自己等級以下的單價(預設)
              </label>
            </div>
            <p class="ms-4 mb-2">
              例:
              VIP2可看到VIP2、VIP1和公開單價，不能看到更高等級（例:VIP3）的價格
            </p>
            <!-- 僅看到自己的價格和公開單價 -->
            <!-- <div class="form-check me-2 p-0" @click="updateConfiguration('orderPage', 'onlyMyVip')">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                value="onlyMyVip"
                id="VipShow2"
                name="VipShow"
                v-model="orderPage"
              />
              <label class="form-check-label" for="VipShow2">
                僅看到自己的價格和公開單價
              </label>
            </div>
            <p class="ms-4 mb-2">
              例: VIP2只能看到VIP2的公開單價，不能看到其他等級的價格
            </p> -->
            <!-- 看的到全部等級的價格 -->
            <div
              class="form-check me-2 p-0"
              @click="updateConfiguration('orderPage', 'all')"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                value="all"
                id="VipShow3"
                name="VipShow"
                v-model="orderPage"
              />
              <label class="form-check-label" for="VipShow3">
                看的到全部等級的價格
              </label>
            </div>
            <p class="ms-4 mb-2">
              例: VIP2可看到所有等級的公開單價，包含更高等級（例:VIP3）的價格
            </p>
          </template>
        </div>
        <!-- 下單頁面多件優惠顯示時機 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1 me-2 mb-1">
            <span class="text-success">可預購</span>時，下單頁面多件優惠顯示時機
          </p>
          <div>
            <div
              class="form-check d-inline-block me-2 p-0"
              @click="updateConfiguration('showDiscounts', 'showAll')"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                value="showAll"
                id="ShowDiscounts1"
                name="showDiscounts"
                v-model="showDiscounts"
              />
              <label class="form-check-label" for="ShowDiscounts1">
                顯示所有多件優惠
              </label>
            </div>
            <div
              class="form-check d-inline-block me-2 p-0"
              @click="updateConfiguration('showDiscounts', 'clickToShow')"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                value="clickToShow"
                id="ShowDiscounts2"
                name="showDiscounts"
                v-model="showDiscounts"
              />
              <label class="form-check-label" for="ShowDiscounts2">
                點擊主要款式在顯示對應的多件優惠
              </label>
            </div>
          </div>
        </div>
        <!-- 允許顧客取消訂單 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1 me-2 mb-1">允許顧客取消訂單</p>
          <!-- 選項 -->
          <p class="mb-2 alert alert-danger" v-if="storeInfo.autoAllocated">
            因設定系統自動配單，故無法設定此功能
          </p>
          <div v-else>
            <div class="form-check d-inline-block me-2 p-0">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                :value="null"
                id="AllowCustomerCancelOrder1"
                name="AllowCustomerCancelOrder"
                v-model="storeInfo.merchOrderCancellationPeriod"
              />
              <label class="form-check-label" for="AllowCustomerCancelOrder1">
                不允許
              </label>
            </div>
            <div class="form-check d-inline-block me-2 p-0">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                value="3"
                id="AllowCustomerCancelOrder2"
                name="AllowCustomerCancelOrder"
                v-model="storeInfo.merchOrderCancellationPeriod"
              />
              <label class="form-check-label" for="AllowCustomerCancelOrder2">
                下單3分鐘內允許取消
              </label>
            </div>
            <div class="form-check d-inline-block me-2 p-0">
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                value="10"
                id="AllowCustomerCancelOrder3"
                name="AllowCustomerCancelOrder"
                v-model="storeInfo.merchOrderCancellationPeriod"
              />
              <label class="form-check-label" for="AllowCustomerCancelOrder3">
                下單10分鐘內允許取消
              </label>
            </div>
          </div>
        </div>
        <!-- 關閉賣場仍可下單 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1 me-2 mb-2">關閉賣場仍可下單</p>
          <AreaLoading v-if="p_loading"></AreaLoading>
          <!-- 選項 -->
          <div v-else>
            <div
              class="form-check d-inline-block me-2 p-0"
              @click="updateConfiguration('closeShopCanStillOrder', false)"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                :value="false"
                id="CloseStoreOrder1"
                name="CloseStoreOrder"
                v-model="closeShopCanStillOrder"
              />
              <label class="form-check-label" for="CloseStoreOrder1">
                不允許
              </label>
            </div>
            <div
              class="form-check d-inline-block me-2 p-0"
              @click="updateConfiguration('closeShopCanStillOrder', true)"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                :value="true"
                id="CloseStoreOrder2"
                name="CloseStoreOrder"
                v-model="closeShopCanStillOrder"
              />
              <label class="form-check-label" for="CloseStoreOrder2">
                允許
              </label>
            </div>
          </div>
        </div>
        <!-- 允許顧客複製賣場商品 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1 me-2 mb-1">允許顧客複製賣場商品</p>
          <!-- 選項 -->
          <AreaLoading v-if="p_loading"></AreaLoading>
          <!-- 選項 -->
          <div v-else>
            <div
              class="form-check d-inline-block me-2 p-0"
              @click="updateConfiguration('cloneMerchToStore', false)"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                :value="false"
                id="CloneMerchToStore1"
                name="CloneMerchToStore"
                v-model="cloneMerchToStore"
              />
              <label class="form-check-label" for="CloneMerchToStore1">
                不允許
              </label>
            </div>
            <div
              class="form-check d-inline-block me-2 p-0"
              @click="updateConfiguration('cloneMerchToStore', true)"
            >
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                :value="true"
                id="CloneMerchToStore2"
                name="CloneMerchToStore"
                v-model="cloneMerchToStore"
              />
              <label class="form-check-label" for="CloneMerchToStore2">
                允許
              </label>
            </div>
          </div>
        </div>
        <!-- 顧客 > 我的訂單 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1 me-2 mb-3">顧客訂單表格欄位顯示</p>
          <!-- 選項 -->
          <AreaLoading v-if="p_loading"></AreaLoading>
          <!-- 選項 -->
          <div v-else>
            <div
              v-for="data in fieldFilter"
              :key="data.id"
              class="select-checkbox mb-4"
            >
              <label>
                <input
                  type="checkbox"
                  name="rows"
                  :checked="data.selected"
                  @click="
                    updateConfiguration(
                      'fieldFilter',
                      data,
                      'p_customerMerchorder'
                    )
                  "
                />
                <span class="checkbox-style" v-if="data.value === 'field1'"
                  >訂單編號</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field2'"
                  >建立者</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field3'"
                  >封面</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field4'"
                  >商品名稱與款式</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field5'"
                  >明細</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field6'"
                  >斷貨狀態</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field7'"
                  >到貨狀態</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field8'"
                  >結單狀態</span
                >
                <span class="checkbox-style" v-else-if="data.value === 'field9'"
                  >付款狀態</span
                >
                <span
                  class="checkbox-style"
                  v-else-if="data.value === 'field10'"
                  >出貨狀態</span
                >
                <span
                  class="checkbox-style"
                  v-else-if="data.value === 'field11'"
                  >建立時間</span
                >
              </label>
            </div>
          </div>
        </div>
        <!-- 主題配色 -->
        <!-- <div class="tw-container border border-1 border-dark mb-3">
          <p class="fw-bolder p-1 me-2 mb-2 text-danger">主題配色 (未做)</p>
          <button
            class="
              tw-btn
              shadow-none
              bg-warning
              text-warning
              border border-1 border-dark
              me-2
            "
          >
            黃色
          </button>
          <button
            class="
              tw-btn
              shadow-none
              bg-danger
              text-danger
              border border-1 border-dark
              me-2
            "
          >
            紅色
          </button>
          <button
            class="
              tw-btn
              shadow-none
              bg-success
              text-success
              border border-1 border-dark
              me-2
            "
          >
            綠色
          </button>
          <button
            class="
              tw-btn
              shadow-none
              bg-primary
              text-primary
              border border-1 border-dark
              me-2
            "
          >
            藍色
          </button>
          <button
            class="
              tw-btn
              shadow-none
              bg-dark
              text-dark
              border border-1 border-dark
              me-2
            "
          >
            紫色
          </button>
        </div> -->
      </div>
      <!-- 右 -->
      <div class="col-lg-7 p-0">
        <!-- 上傳賣場圖片 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <div class="mb-2">
            <span class="fw-bolder p-1 me-2">上傳賣場圖片</span>
            <span class="d-inline-block">
              <button
                v-if="!storeInfo.bannerImageHash"
                class="tw-btn tw-btn-success me-2"
                @click="showModal('uploadBannerImage')"
              >
                上傳圖片
              </button>
              <button class="tw-btn tw-btn-danger" @click="delBanner">
                刪除
              </button>
            </span>
          </div>
          <p class="p-1 mb-2">建議照片尺寸 3:1 長方形圖片</p>
          <div class="row">
            <div class="col-md-12 col-12 mb-2 border">
              <Image
                :imageHash="storeInfo.bannerImageHash"
                v-if="storeInfo.bannerImageHash"
                :alt="storeInfo.bannerImageHash"
                style="max-width: 100%; max-height: 100%"
              ></Image>
              <img
                style="max-width: 100%; max-height: 100%"
                src="@/assets/other-images/banner_about-min.png"
                v-else
              />
            </div>
          </div>
        </div>
        <!-- 賣場促銷圖片 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <div class="mb-2">
            <span class="fw-bolder p-1 me-2">賣場促銷圖片</span>
            <button
              class="tw-btn tw-btn-success me-2"
              @click="showModal('uploadPromotionalImage')"
            >
              上傳圖片
            </button>
          </div>
          <p class="p-1 mb-2">建議照片尺寸 3:1/3:2 長方形圖片</p>
          <AreaLoading v-if="storePictureLinks.loading"></AreaLoading>
          <template v-else-if="storePictureLinks.data.length > 0">
            <div
              class="d-flex d-inline-block border align-items-center"
              v-for="data in storePictureLinks.data"
              :key="data.id"
            >
              <span style="width: 25%; min-width: 155px">
                <Image
                  :imageHash="data.imageHash"
                  v-if="data.imageHash"
                  :alt="data.imageHash"
                  style="width: 150px; height: 150px"
                ></Image>
              </span>
              <span style="width: 70%">
                <template v-if="data.url">
                  <div style="word-break: break-all">連結網址：</div>
                  <a :href="data.url" style="word-break: break-all">
                    {{ data.url }}
                  </a>
                </template>
                <p class="text-danger" v-else>無設定連結網址</p>
                <div class="text-end">
                  <button
                    class="tw-btn tw-btn-danger"
                    @click="delPromotionalImage(data.id)"
                  >
                    刪除
                  </button>
                </div>
              </span>
            </div>
          </template>
          <p class="fw-bolder text-danger ms-1" v-else>
            本賣場尚未設定促銷圖片
          </p>
        </div>
        <!-- 商品分類 -->
        <div class="tw-container border border-1 border-dark mb-3">
          <div class="mb-2">
            <span class="fw-bolder p-1 me-2 mb-2">商品分類
              <a href="https://youtu.be/f0c_KeXghMo?si=p0KKpQxjKFFFQhKo" target="_blank">
                <img
                  class="info-icon"
                  src="@/assets/icon/interrogation.png"
                />
              </a>
            </span>
            <div class="d-inline-block mt-2">
              <button
                class="tw-btn tw-btn-success d-inline-block me-2"
                @click="showModal('addCategory')"
              >
                新增分類
              </button>
              <span class="form-check form-switch d-inline-block p-0 me-4">
                <input
                  class="form-check-input pointer m-0 me-2"
                  type="checkbox"
                  role="switch"
                  id="OpenDetails"
                  v-model="checkAllCollapse"
                />
                <label class="form-check-label" for="OpenDetails">
                  <template v-if="!checkAllCollapse"> 展開所有明細 </template>
                  <template v-else> 收起所有明細 </template>
                </label>
              </span>
            </div>
          </div>
          <AreaLoading v-if="categories.loading"></AreaLoading>
          <table
            class="table text-start"
            v-else-if="categories.data.length > 0"
          >
            <!-- 表格 -->
            <Draggable
              v-model="categories.data"
              class="draggable"
              @start="drag = true"
              @end="drag = false"
              item-key="sequance"
              tag="tbody"
              handle=".handle"
            >
              <template #item="{ element }">
                <div>
                  <!-- 表頭 -->
                  <div
                    class="row border-dark tw-bg-main"
                    style="border-style: solid; border-width: 1px 0px 0px 0"
                  >
                    <i class="bi bi-arrows-move handle col-12 col-md-1"></i>
                    <!-- 類別 -->
                    <div class="col-12 col-md-2">
                      <span class="fw-bolder">分類：</span>{{ element.name }}
                    </div>
                    <!-- 開放時間 -->
                    <div class="col-12 col-md-3">
                      <span class="fw-bolder">狀態：</span>
                      <span v-if="element.openInterval">
                        <span v-if="new Date() * 1 < element.enableTime">
                          <span class="text-danger">尚未開始</span>
                        </span>
                        <span
                          v-else-if="
                            element.enableTime <= new Date() * 1 &&
                            new Date() * 1 <= element.expirationTime
                          "
                        >
                          <span class="text-success">開放中</span>
                        </span>
                        <span
                          v-else-if="new Date() * 1 > element.expirationTime"
                        >
                          <span class="text-warning">已結束</span>
                        </span>
                      </span>
                      <span v-else>
                        <span class="text-danger">未設定時間</span>
                      </span>
                    </div>
                    <!-- 查看明細 -->
                    <div class="col-12 col-md-2">
                      <a
                        class="pointer"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#detail${element.id}`"
                        aria-expanded="false"
                        @click="recordShowDetail(`detail${element.id}`)"
                      >
                        查看明細<span class="text-dark">▼</span>
                      </a>
                    </div>
                    <!-- 子標題 -->
                    <div class="col-12 col-md-4">
                      <p>
                        <span class="fw-bolder">子標題：</span>
                        <span class="test-secondary" v-if="element.subtitle">{{
                          element.subtitle
                        }}</span>
                        <span class="text-danger" v-else>未填寫喔!</span>
                      </p>
                    </div>
                  </div>
                  <!-- 明細 -->
                  <div
                    class="child collapse fade collapse-detail-show border-dark"
                    style="border-style: solid; border-width: 1px 0 1px 0"
                    :id="`detail${element.id}`"
                    :ref="`detail${element.id}`"
                  >
                    <div class="row" :id="element.id">
                      <div class="col-12 col-md-6 p-2">
                        <div>
                          <!-- 隱藏分類 -->
                          <div class="form-check form-switch mb-2">
                            <label :for="`outsideHidden${element.id}`">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :id="`outsideHidden${element.id}`"
                                v-model="element.hidden"
                                @click="hiddenClassData(element)"
                              />
                              是否隱藏
                            </label>
                          </div>
                          <!-- 開放時間區間 -->
                          <p class="mb-2">
                            開放時間：
                            <span v-if="element.openInterval"
                              >{{ element.startTimeToLocal }} ~
                              {{ element.endTimeToLocal }}</span
                            >
                            <span v-else class="text-danger">未設定</span>
                          </p>
                          <!-- 顧客群組： -->
                          <p class="mb-2">
                            顧客群組：
                            <template
                              v-if="element.customerGroups.length !== 0"
                            >
                              <span
                                v-for="group in element.customerGroups"
                                :key="group.id"
                                >{{ group.name }}&ensp;
                              </span>
                            </template>
                            <span v-else class="text-danger"
                              >無設定顧客群組</span
                            >
                          </p>
                          <!-- VIP -->
                          <p class="mb-2">
                            VIP 選擇：
                            <span v-if="element.storeVipId">{{
                              element.storeVip.name
                            }}</span>
                            <span v-else class="text-danger">未選取</span>
                          </p>
                          <!-- 按鈕 -->
                          <div>
                            <button
                              class="tw-btn tw-btn-success mb-2 me-2"
                              @click="showModal('editClass', element)"
                            >
                              編輯分類
                            </button>
                            <button
                              class="tw-btn tw-btn-danger mb-2"
                              @click="showModal('delCategory', element)"
                            >
                              刪除分類
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- 分類圖片 -->
                      <div class="col-12 col-md-6 p-2">
                        <div class="d-block ms-2 mb-3">
                          <button
                            class="tw-btn tw-btn-success me-2"
                            type="button"
                            @click="showModal('uploadClassImage', element.id)"
                          >
                            上傳圖片
                          </button>
                          <button
                            class="tw-btn tw-btn-danger"
                            v-if="element.imageHash"
                            @click="delClassImage(element.id)"
                          >
                            刪除圖片
                          </button>
                        </div>
                        <div>
                          <Image
                            class="mb-2 img-size"
                            :imageHash="element.imageHash"
                            v-if="element.imageHash"
                            :alt="element.imageHash"
                            style="max-width: 100%; max-height: 100%"
                          ></Image>
                          <p class="text-danger fw-bolder" v-else>
                            尚未設定圖片!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Draggable>
          </table>
          <p class="fw-bolder text-danger" v-else>本賣場尚未設定商品分類</p>
        </div>
      </div>
    </div>
  </div>
  <!-- 申請短網址 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="applyShortUrlModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">申請短網址</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="shortCut" class="alert alert-primary mb-2">
            申請後短網址結果 : {{ storeUrl + "s/" + shortCut }}
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="ShortUrlInputD4"
              >{{ storeUrl }}s/</span
            >
            <input
              type="text"
              class="form-control"
              id="ShortUrlInput"
              aria-describedby="ShortUrlInput"
              v-model="shortCut"
              placeholder="請輸入短網址"
              maxlength="20"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="applyShortUrl"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 圖片裁切(上傳賣場圖片、賣場促銷圖片) modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="cutImageModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            <template v-if="uploadImageStatus === 'uploadBannerImage'"
              >上傳賣場圖片</template
            >
            <template v-else-if="uploadImageStatus === 'uploadPromotionalImage'"
              >上傳賣場促銷圖片</template
            >
            <template v-else-if="uploadImageStatus === 'uploadClassImage'"
              >上傳商品分類圖片</template
            >
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 賣場促銷圖片連結 -->
          <div
            class="form-floating mb-3"
            v-if="uploadImageStatus === 'uploadPromotionalImage'"
          >
            <input
              type="text"
              class="form-control"
              id="PromotionUrl"
              v-model="promotionUrl"
              placeholder="請輸促銷圖片商品網址連結"
            />
            <label for="PromotionUrl">請輸入連結網址(非必填)</label>
          </div>
          <!-- 圖片裁切 -->
          <div class="input-group">
            <input
              ref="imgFile"
              type="file"
              accept=".jpg,.png,.jpeg"
              class="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
              @change="selectImagechange"
            />
          </div>
          <!-- 圖片預覽方式 -->
          <template v-if="cropperOption.img !== 0">
            <!-- 一般預覽(賣場促銷圖片) -->
            <img
              v-if="uploadImageStatus === 'uploadPromotionalImage'"
              style="max-width: 100%; max-height: 100%"
              :src="cropperOption.img"
            />
            <!-- 裁切模式(賣場Banner) -->
            <template v-else>
              <Cropper
                ref="cropper"
                :src="cropperOption.img"
                :stencil-size="cropperOption.stencilSize"
                :stencil-props="cropperOption.stencilProps"
                :resize-image="cropperOption.resizeImage"
                :image-restriction="cropperOption.imageRestriction"
                :canvas="cropperOption.canvasSize"
                style="width: 100%; height: 300px"
              />
            </template>
          </template>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            v-if="uploadImageStatus === 'uploadBannerImage'"
            @click="uploadNewBanner"
          >
            確認
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            v-if="uploadImageStatus === 'uploadPromotionalImage'"
            @click="uploadPromotionalImage"
          >
            確認
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            v-if="uploadImageStatus === 'uploadClassImage'"
            @click="uploadClassImage"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增/編輯商品分類 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateCategoryModal"
  >
    <div class="modal-dialog modal-lg" style="max-width: 860px">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-if="updateClassStatus === 'addCategory'"
          >
            新增商品分類
          </h5>
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-else-if="updateClassStatus === 'editClass'"
          >
            編輯商品分類
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 分類名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CategoryName"
              maxlength="15"
              placeholder="請輸入分類名稱"
              v-model="categoryData.name"
            />
            <label for="CategoryName">請輸入分類名稱(15字)</label>
          </div>
          <!-- 子標題 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="CategoryChildName"
              maxlength="50"
              placeholder="輸入子標題"
              v-model="categoryData.subtitle"
            />
            <label for="CategoryChildName">輸入子標題(50字)</label>
          </div>
          <!-- VIP等級 -->
          <div class="mb-3">
            <AreaLoading v-if="storeVips.loading"></AreaLoading>
            <select
              class="form-select"
              v-model="categoryData.storeVipId"
              v-else
            >
              <option value="null">不設定VIP等級</option>
              <template v-for="vip in storeVips.data" :key="vip.id">
                <option :value="vip.id" v-if="vip.id <= storeInfo.storeVipId">
                  {{ vip.name }}
                </option>
              </template>
            </select>
          </div>
          <!-- 是否隱藏 -->
          <div class="form-check form-switch mb-3">
            <label class="form-check-label fs-content fw-bolder" for="hidden"
              >是否隱藏</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              id="hidden"
              v-model="categoryData.hidden"
            />
          </div>
          <!-- 設定開方式時間 -->
          <div class="form-check form-switch mb-3">
            <label class="form-check-label fs-content fw-bolder" for="setTime"
              >設定開放時間</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              id="setTime"
              v-model="categoryData.openInterval"
            />
          </div>
          <div class="row mb-3 m-0" v-if="categoryData.openInterval">
            <!-- 開始時間 -->
            <div class="col-6 p-1 mb-2">
              <label class="fw-bolder text-danger mb-2">*開始時間</label>
              <div class="form-floating">
                <input
                  class="form-control"
                  type="date"
                  id="StartTime"
                  placeholder="開始時間"
                  v-model="categoryData.enableTime"
                />
                <label for="StartTime" class="mb-2">開始時間</label>
              </div>
            </div>
            <!-- 結束時間 -->
            <div class="col-6 p-1">
              <label class="fw-bolder text-danger mb-2">*結束時間</label>
              <div class="form-floating">
                <input
                  class="form-control"
                  type="date"
                  id="EndTime"
                  placeholder="結束時間"
                  v-model="categoryData.expirationTime"
                />
                <label for="EndTime" class="mb-2">結束時間</label>
              </div>
            </div>
          </div>
          <!-- 顧客群組 -->
          <div class="form-check form-switch mb-3">
            <div class="mb-3">
              <label
                class="form-check-label fs-content fw-bolder"
                for="setCustomerGroup"
                >選擇顧客群組</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="setCustomerGroup"
                name="setCustomerGroup"
                v-model="is_selectCutomerGroup"
              />
            </div>
            <AreaLoading v-if="customerGroups.loading"></AreaLoading>
            <div
              class="border border-dark w-50 p-3"
              style="border-radius: 10px"
              v-else-if="is_selectCutomerGroup"
            >
              <div
                class="mb-2"
                v-for="data in customerGroups.data"
                :key="data.id"
              >
                <input
                  class="me-2"
                  type="checkbox"
                  :value="data.id"
                  name="customerGroup"
                  :id="`addCategory${data.id}`"
                  v-model="selectCutomerGroups"
                />
                <label :for="`addCategory${data.id}`" style="font-size: 18px">
                  {{ data.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            v-if="updateClassStatus === 'addCategory'"
            @click="addCategory"
          >
            確認
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            v-else-if="updateClassStatus === 'editClass'"
            @click="editClass"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除商品分類的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delCategoryModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold"
          >
            刪除商品分類
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p
            class="alert alert-warning"
            v-if="
              selectItems.length > 0 &&
              selectItems[0].merchandiseList.length > 0
            "
          >
            此分類尚有商品關聯，還想繼續執行嗎 ?
          </p>
          <p>確定要刪除這些分類 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="delClass">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import collapse from "bootstrap/js/dist/collapse";
// mixin
import { HandleImages } from '../../../methods/mixins/handleImages'
import { Permission } from '@/methods/mixins/permission';
// components
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import Draggable from "vuedraggable"
import CountDown from '@/components/tools/CountDown.vue'

export default {
  components: { Cropper, Draggable, CountDown },
  data() {
    return {
      // modal
      applyShortUrlModal: {},
      cutImageModal: {},
      updateCategoryModal: {},
      delCategoryModal: {},
      // data
      serverToken: '',
      storeId: 0,
      storeInfo: {},
      // 短網址
      storeUrl : '',
      shortCut: '',
      showShortUrl: false,
      // 上傳圖片(賣場Banner、賣場促銷圖片)
      uploadImageStatus: '',
      promotionUrl: '',
      cropperOption: {
        img: "",
        stencilSize: {
          width: 300,
          height: 100,
        },
        stencilProps: {
          handlers: {},
          movable: false,
          scalable: false,
          // aspectRatio: 3,
        },
        canvasSize: {
          width: 1000,
          height: 700,
        },
        resizeImage: {
          adjustStencil: false,
        },
        imageRestriction: "stencil",
      },
      selectItems: [],
      propsSelectInfos: {
        show: 'name',
        data: []
      },
      // 商品分類
      // 1. 新增/編輯
      updateClassStatus: '',
      selectCutomerGroups: [],
      is_selectCutomerGroup: false,
      categoryData: {
        storeId: "",
        name: "",
        subtitle: "",
        openInterval: false,
        enableTime: "",
        expirationTime: "",
        storeVipId: null,
        hidden: false,
      },
      // 2. 詳細資訊
      checkCollapseRecord: [],
      checkAllCollapse: false,
      selectClassId: null,
      // storeVips
      storeVips: {
        loading: false,
        data: []
      },
      // 顧客群組資訊
      customerGroups: {
        loading: false,
        data: []
      },
      // 賣場促銷圖片資料
      storePictureLinks: {
        loading: false,
        data: []
      },
      // 商品分類
      categories: {
        loading: false,
        data: []
      }
    }
  },
  mixins: [HandleImages, Permission],
  created() {
    this.initialization()
    this.getPrefer()
    const getData = ['getStoreVips','getCustomerGroups','getStorePictureLinks','getMerchClassifications']
    this.getStoreInfo(getData)
  },
  mounted() {
    this.createModals([
      'applyShortUrlModal','cutImageModal','updateCategoryModal', 'delCategoryModal'
    ])
  },
  watch: {
    // 允許顧客取消訂單
    'storeInfo.merchOrderCancellationPeriod': {
      handler(val, oldVal) {
        if (oldVal!==undefined) this.allowCustomerCancelOrder(parseInt(val))
      }
    },
    // 重新排序商品分類
    categories: {
      handler(val, oldVal) {
        const saveSequence = val.data.some((item, index) => {
          return item.sequence !== index
        })
        if (JSON.stringify(oldVal.data) === "[]" || !saveSequence) return
        this.merchClassificationReorderSequence(val.data);
      },
      deep: true,
    },
    // 監聽展開所有明細 collapse
    checkAllCollapse(val, oldVal) {
      this.checkCollapseRecord = []
      if (val) this.categories.data.forEach(item => this.checkCollapseRecord.push(`detail${item.id}`))
      this.showDetail('all')
    },
  },
  computed: {
    // 偏好 > loading
    p_loading() {
      return this.$store.state.Prefer.p_loading
    },
    // 偏好 > 商品小卡價格呈現方式
    merchCard() {
      let merchCard = 'normal'
      this.$store.state.Prefer.p_shop.merchCard.forEach(item => {
        if (item.selected) merchCard = item.value
      })
      return merchCard
    },
    // 偏好 > 個人賣場VIP價格呈現方式
    orderPage() {
      let orderPage = 'all'
      this.$store.state.Prefer.p_shop.orderPage.forEach(item => {
        if (item.selected) orderPage = item.value
      })
      return orderPage
    },
    // 偏好 > 關閉賣場仍可下單
    closeShopCanStillOrder() {
      return this.$store.state.Prefer.p_shop.closeShopCanStillOrder[0].selected
    },
    // 偏好 > 商品小卡顯示顯示到貨時間
    showArrivalTime() {
      return this.$store.state.Prefer.p_shop.showArrivalTime[0].selected
    },
    // 偏好 > 商品小卡顯示庫存
    showStock() {
      return this.$store.state.Prefer.p_shop.showStock[0].selected
    },
    // 偏好 > 訂單大於多少顯示熱賣
    sellQuantity: {
      get() {
        let sellQuantity = 'doNotShow'
        this.$store.state.Prefer.p_shop.sellQuantity.forEach(item => {
          if (item.selected) sellQuantity = item.value
        })
        return sellQuantity
      },
      set(updateData) {
        this.updateConfiguration('sellQuantity', updateData)
      },
    },
    // 偏好 > 允許顧客複製商品
    cloneMerchToStore() {
      return this.$store.state.Prefer.p_shop.cloneMerchToStore[0].selected
    },
    // 偏好 > 多件優惠顯示時機
    showDiscounts() {
      let showDiscounts = 'showAll'
      this.$store.state.Prefer.p_shop.showDiscounts.forEach(item => {
        if (item.selected) showDiscounts = item.value
      })
      return showDiscounts
    },
    // 偏好 > 顧客 > 我的訂單欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_customerMerchorder.fieldFilter
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.storeUrl = `${process.env.VUE_APP_ORIGINURL}/`
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'applyShortUrl') {
        this.shortCut = ''
        this.applyShortUrlModal.show()
      } else if (status === 'uploadBannerImage') {
        this.uploadImageStatus = 'uploadBannerImage'
        this.$refs.imgFile.value = ''
        this.cropperOption.img = 0
        this.cutImageModal.show()
      } else if (status === 'uploadPromotionalImage') {
        this.uploadImageStatus = 'uploadPromotionalImage'
        this.promotionUrl = ''
        this.$refs.imgFile.value = ''
        this.cropperOption.img = 0
        this.cutImageModal.show()
      } else if (status === 'addCategory') {
        this.updateClassStatus = 'addCategory'
        this.is_selectCutomerGroup = false
        this.selectCutomerGroups = []
        this.categoryData = {
          storeId: this.storeId,
          name: "",
          subtitle: "",
          openInterval: false,
          enableTime: "",
          expirationTime: "",
          storeVipId: null,
          hidden: false,
        },
        this.updateCategoryModal.show()
      } else if (status === 'uploadClassImage') {
        this.uploadImageStatus = 'uploadClassImage'
        this.$refs.imgFile.value = ''
        this.cropperOption.img = 0
        this.selectClassId = item
        this.cutImageModal.show()
      } else if (status === 'editClass') {
        console.log(item)
        this.updateClassStatus = 'editClass'
        this.is_selectCutomerGroup = item.customerGroups.length !== 0 ? true : false
        this.selectCutomerGroups = []
        item.customerGroups.forEach(data => {
          this.selectCutomerGroups.push(data.id)
        })  
        this.categoryData = {
          id: item.id,
          name: item.name,
          subtitle: item.subtitle,
          openInterval: item.openInterval,
          enableTime: item.openInterval ? this.$methods.moment(item.enableTime).format('YYYY-MM-DD') : null,
          expirationTime: item.openInterval ? this.$methods.moment(item.expirationTime).format('YYYY-MM-DD') : null,
          storeVipId: item.storeVipId,
          hidden: item.hidden,
          imageHash: item.imageHash,
          merchClassCustomerGroupLinks: item.merchClassCustomerGroupLinks
        }
        this.updateCategoryModal.show()
      } else if (status === 'delCategory') {
        // 刪除分類
        this.propsSelectInfos.data = []
        this.selectItems = []
        this.selectItems.push(item)
        this.propsSelectInfos.data = this.selectItems
        this.delCategoryModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 更新偏好
    updateConfiguration(item, data, category) {
      console.log(data)
      let finalData = data
      let customerMerchorderFields = JSON.parse(JSON.stringify(this.fieldFilter))
      // 如果是顧客 > 我的訂單欄位篩選，data 值需要特別處理
      if (category && category === 'p_customerMerchorder') {
        finalData = []
        customerMerchorderFields.forEach(item => {
          if (item.id === data.id) item.selected = !data.selected
          if (item.selected) {
            finalData.push({
              index: item.value,
            })
          }
        })
      }
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: category ? category : 'p_shop',
        item: item,
        data: finalData
      })
    },
    // 取得賣場資訊 
    getStoreInfo(getData = []) {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let getDataArray = []
      if(getData) getDataArray = JSON.parse(JSON.stringify(getData))
      // storeVips
      if (getData.includes('getStoreVips')) {
        this.storeVips = {
          loading: true,
          data: []
        }
      }
      // 顧客群組資訊
      if (getData.includes('getCustomerGroups')) {
        this.customerGroups = {
          loading: true,
          data: []
        }
      }
      // 賣場促銷圖片
      if (getData.includes('getStorePictureLinks')) {
        this.storePictureLinks = {
          loading: true,
          data: []
        }
      }
      // 商品分類
      if (getData.includes('getMerchClassifications')) {
        getDataArray[getData.indexOf('getMerchClassifications')] = 'getMerchClassifications{getMerchandiseList,getCustomerGroups,getMerchClassCustomerGroupLinks,getStoreVip}'
        this.categories = {
          loading: true,
          data: []
        }
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: `{${getDataArray.join()}}`
        },
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // storeVips
            if (getData.includes('getStoreVips')) {
              vm.storeVips = {
                loading: false,
                data: storeInfo.storeVips
              }
            }
            // 顧客群組資訊
            if (getData.includes('getCustomerGroups')) {
              vm.customerGroups = {
                loading: false,
                data: storeInfo.customerGroups
              }
            }
             // 賣場促銷圖片
            if (getData.includes('getStorePictureLinks')) {
              vm.storePictureLinks = {
                loading: false,
                data: storeInfo.storePictureLinks
              }
            }
            // 商品分類
            if (getData.includes('getMerchClassifications')) {
              vm.categories = {
                loading: false,
                data: storeInfo.merchClassifications
              }
              vm.categories.data.forEach(item => {
                item.startTimeToLocal = vm.$methods.moment(item.enableTime).format("YYYY-MM-DD")
                item.endTimeToLocal = vm.$methods.moment(item.expirationTime).format("YYYY-MM-DD")
              })
              setTimeout(() => {vm.showDetail('updates')},500)  
            }
          }
          setTimeout(() => {
            vm.$methods.switchLoading('hide')
          }, 500)
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
    // 更換顯示網址
    changeUrl() {
      if (!this.showShortUrl && !this.storeInfo.shortCut) {
        this.showModal('applyShortUrl')
      } else this.showShortUrl = !this.showShortUrl
    },
    // 申請短網址
    applyShortUrl() {
      if (!this.shortCut) return this.SweetAlert('other', '請輸入欲申請短網址的名稱')
      this.$methods.switchLoading('show')
      const vm = this
      const applyShortUrlApi = `${process.env.VUE_APP_API}/store/registerShortCut`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('id', this.storeId)
      data.append('shortCut', this.shortCut)
      $.ajax({
        type: 'PUT',
        async: true,
        url: applyShortUrlApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo()
            vm.showShortUrl = true
            vm.applyShortUrlModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 複製
    copy(url) {
      console.log(url)
      let createInput = document.createElement("input") // 建立一個隱藏input（重要！）
      if (!this.showShortUrl) createInput.value = `${this.storeUrl}customer/store/${this.storeId}/shop`
      else createInput.value = this.storeUrl + 's/' + this.storeInfo.shortCut
      if (url) createInput.value = url
      createInput.id = "copy"
      document.body.appendChild(createInput)
      createInput.select() // 選擇物件
      document.execCommand("Cut") // 執行瀏覽器複製命令
      document.getElementById("copy").remove()
      // 組SweetAlertTitle
      const SweetAlertTitle = {
        icon: 'success',
        title: {
          show: true,
          value: '複製成功'
        },
        text: {
          show: false,
          value: ''
        },
        timer: 1000
      }
      this.SweetAlert('setByMyself', SweetAlertTitle)
    },
    // 更新賣場介紹 
    updateIntroduction() {
      this.$methods.switchLoading('show')
      const vm = this
      const updateIntroductionApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.storeId,
        attributes: {
          setIntroduction: this.storeInfo.introduction
        }
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateIntroductionApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })       
    },
    // 允許顧客取消訂單
    allowCustomerCancelOrder(time) {
      this.$methods.switchLoading('show')
      const vm = this
      const allowCustomerCancelOrderApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.storeId,
        attributes: {
          setMerchOrderCancellationPeriod: time
        }
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: allowCustomerCancelOrderApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 選擇圖片完的事件
    selectImagechange() {
      const img = this.$refs.imgFile.files
      if (img.length === 0) return this.cropperOption.img = 0
      const result = this.handleImages(img)
      
      if (result.errorSize.length > 0) return this.SweetAlert('other', '圖片大小超過 10M')
      if (result.errorType.length > 0) return this.SweetAlert('other', '圖片類型錯誤')
      
      if (result.newImages.length === 0) return
      const reader = new FileReader()
      reader.readAsDataURL(img[0])
      const vm = this
      reader.onload = function (e) {
        vm.cropperOption.img = e.target.result
      }
    },
    // 上傳賣場 banner
    uploadNewBanner() {
      this.$methods.switchLoading('show')
      const vm = this
      const uploadNewBannerApi = `${process.env.VUE_APP_API}/store/uploadNewBanner`
      const header = {
        authorization: this.serverToken
      }
      // 取得檔案檔名
      if (!this.$refs.cropper) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請選擇圖片')
      } 
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      const img_base64 = canvas.toDataURL()
      const data = [{
        id: this.storeId,
        image: img_base64.split(",")[1]
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: uploadNewBannerApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo()
            vm.cutImageModal.hide()
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除賣場 banner
    delBanner() {
      this.$methods.switchLoading('show')
      const vm = this
      const delBannerApi = `${process.env.VUE_APP_API}/store/setAttribute`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        id: this.storeId,
        attributes: {
          setBannerImageHash: null
        }
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: delBannerApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo()
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 上傳賣場促銷圖片
    uploadPromotionalImage() {
      this.$methods.switchLoading('show')
      const vm = this
      const uploadPromotionalImageApi = `${process.env.VUE_APP_API}/store/createPictureLinks`
      const header = {
        authorization: this.serverToken
      }
      // 圖片處理
      if (!this.$refs.imgFile.value) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請選擇圖片')
      } 
      let files = document.querySelector("input[type=file]").files
      const result = this.handleImages(files)

      if (result.errorSize.length > 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '圖片大小超過 10M')
      }
      if (result.errorType.length > 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '圖片類型錯誤')
      }

      if (result.newImages.length === 0) return this.$methods.switchLoading('hide')


      let imgfile = result.newImages[0].files
      const data = new FormData()
      data.append('id', this.storeId)
      data.append('files', imgfile)
      data.append('urls', this.promotionUrl ? encodeURI(this.promotionUrl) : null )
      
      $.ajax({
        type: 'POST',
        async: true,
        url: uploadPromotionalImageApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo(['getStorePictureLinks'])
            vm.cutImageModal.hide()
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除賣場促銷圖片
    delPromotionalImage(id) {
      this.$methods.switchLoading('show')
      const vm = this
      const delPromotionalImageApi = `${process.env.VUE_APP_API}/store/deletePictureLinks`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('linkIds', id)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delPromotionalImageApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo(['getStorePictureLinks'])
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 新增商品分類
    addCategory() {
      if (this.categoryData.openInterval && (!this.categoryData.enableTime || !this.categoryData.expirationTime )) return this.SweetAlert('other', '開放時間請填寫完整')
      if (this.selectCutomerGroups.length === 0 && this.is_selectCutomerGroup) return this.SweetAlert('other', '您尚未選擇顧客群組')
      this.$methods.switchLoading('show')
      const vm = this
      const addCategoryApi = `${process.env.VUE_APP_API}/merchClassification/create`
      const header = {
        authorization: this.serverToken
      }
      const merchClassification = JSON.parse(JSON.stringify(this.categoryData))
      merchClassification.enableTime = this.categoryData.openInterval ? new Date(this.categoryData.enableTime) * 1 : null
      merchClassification.expirationTime = this.categoryData.openInterval ? new Date(this.categoryData.expirationTime).setHours(23, 59, 59) * 1 : null
      const data = [{
        merchClassification: merchClassification,
        groupIds: this.selectCutomerGroups ? this.selectCutomerGroups : []
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: addCategoryApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.updateCategoryModal.hide()
            vm.getStoreInfo(['getMerchClassifications'])
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 紀錄開啟明細資料 
    recordShowDetail(id) {
      const index = this.checkCollapseRecord.indexOf(id)
      if (index !== -1) this.checkCollapseRecord.splice(index, 1)
      else this.checkCollapseRecord.push(id)
      if (this.checkCollapseRecord.length === this.categories.data.length) this.checkAllCollapse = true
      else if (this.checkCollapseRecord.length === 0) this.checkAllCollapse = false
    },
    // 展開/關閉所有商品分類
    showDetail(status) {
      // 展開所有明細
      if (status === 'all') {
        const getAllCollepse = document.getElementsByClassName(
          "collapse-detail-show"
        )
        if (this.checkAllCollapse) {
          for (let i = 0; i < getAllCollepse.length; i++) {
            const Collepse = new collapse(getAllCollepse[i], {
              toggle: false,
            })
            Collepse.show()
          }
        } else {
          for (let i = 0; i < getAllCollepse.length; i++) {
            const Collepse = new collapse(getAllCollepse[i], {
              toggle: false,
            })
            Collepse.hide()
          }
        }
      } else if (status === 'updates') { 
        // 更新資料時復原原本開啟狀態
        this.checkCollapseRecord.forEach(openid => {
          if (document.getElementById(openid)) {
            const Collepse = new collapse(document.getElementById(openid), {
              toggle: false,
            })
            Collepse.show()
          }
        })
        
      }
    },
    // 是否隱藏商品分類資料 
    hiddenClassData(classdata) {
      this.$methods.switchLoading('show')
      const vm = this
      const hiddenClassDataApi = `${process.env.VUE_APP_API}/merchClassification/update`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        merchClassification: {
          id: classdata.id,
          name: classdata.name,
          subtitle: classdata.subtitle,
          openInterval: classdata.openInterval,
          enableTime: classdata.enableTime,
          expirationTime: classdata.expirationTime,
          storeVipId: classdata.storeVipId,
          hidden: !classdata.hidden,
          imageHash: classdata.imageHash,
        }
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: hiddenClassDataApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo(['getMerchClassifications'])
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 編輯分類 
    editClass() {
      if (this.categoryData.openInterval && (!this.categoryData.enableTime || !this.categoryData.expirationTime )) return this.SweetAlert('other', '開放時間請填寫完整')
      if (this.selectCutomerGroups.length === 0 && this.is_selectCutomerGroup) return this.SweetAlert('other', '您尚未選擇顧客群組')
      this.$methods.switchLoading('show')
      const vm = this
      const editClassApi = `${process.env.VUE_APP_API}/merchClassification/update`
      const header = {
        authorization: this.serverToken
      }
      const merchClassification = JSON.parse(JSON.stringify(this.categoryData))
      merchClassification.enableTime = this.categoryData.openInterval ? new Date(merchClassification.enableTime) * 1 : null
      merchClassification.expirationTime = this.categoryData.openInterval ? new Date(merchClassification.expirationTime).setHours(23, 59, 59) * 1 : null
      // 顧客群組 (links)
      const bSelectCutomerGroups = this.categoryData.merchClassCustomerGroupLinks
      const ASelectCutomerGroups = JSON.parse(JSON.stringify(this.selectCutomerGroups))
      const links = []
      // 如果顧客群組開關關閉則檢查預設是否有關聯過群組
      if (!this.is_selectCutomerGroup) {
        bSelectCutomerGroups.forEach(customer => {
          let customerObj = {
            selected: false,
            linkId: customer.id
          }
          links.push(customerObj)
        })
      } else {
        bSelectCutomerGroups.forEach(customer => {
          let customerObj = {}
          const index = ASelectCutomerGroups.indexOf(customer.customerGroupId)
          // 更改(前)資料有勾選且沒被變動
          if (index !== -1) ASelectCutomerGroups.splice(index, 1)
          // 更改(前)資料有勾選，但被取消刪除
          else {
            customerObj = {
              selected: false,
              linkId: customer.id
            }
            links.push(customerObj)
          }
        })
        // 更改(後) 新增的顧客群組
        ASelectCutomerGroups.forEach(customerId => {
          let customerObj = {
              selected: true,
              customerGroupId: customerId
          }
          links.push(customerObj)
        })
      }   
      delete merchClassification.merchClassCustomerGroupLinks
      const data = [{
        merchClassification: merchClassification,
        links: links
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: editClassApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.updateCategoryModal.hide()
            vm.getStoreInfo(['getMerchClassifications'])
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除分類
    delClass() {
      this.$methods.switchLoading('show')
      const vm = this
      const delClassApi = `${process.env.VUE_APP_API}/merchClassification/delete`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('classificationIds', this.selectItems[0].id)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delClassApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo(['getMerchClassifications'])
            vm.SweetAlert(res.code, res.message)
            vm.delCategoryModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 上傳商品分類圖片 
    uploadClassImage() {
      this.$methods.switchLoading('show')
      const vm = this
      const uploadClassImageApi = `${process.env.VUE_APP_API}/merchClassification/uploadPicture`
      const header = {
        authorization: this.serverToken
      }
      // 取得檔案檔名
      if (!this.$refs.cropper) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請選擇圖片')
      } 
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      const img_base64 = canvas.toDataURL()
      const data = new FormData()
      data.append('classificationId', this.selectClassId)
      data.append('image', img_base64.split(",")[1])
      $.ajax({
        type: 'PUT',
        async: true,
        url: uploadClassImageApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.cutImageModal.hide()
            vm.getStoreInfo(['getMerchClassifications'])
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除商品分類圖片 
    delClassImage(id) {
      this.$methods.switchLoading('show')
      const vm = this
      const delClassImageApi = `${process.env.VUE_APP_API}/merchClassification/removePicture`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('classificationId', id)
      $.ajax({
        type: 'PUT',
        async: true,
        url: delClassImageApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getStoreInfo(['getMerchClassifications'])
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 重新排序商品分類
    merchClassificationReorderSequence(newCategories) {
      this.$methods.switchLoading('show')
      const vm = this
      const merchClassificationReorderSequenceApi = `${process.env.VUE_APP_API}/merchClassification/reorderSequence`
      const header = {
        authorization: this.serverToken
      }
      const classificationIds = []
      newCategories.forEach(item => classificationIds.push(item.id))
      const data = new FormData()
      data.append('classificationIds', classificationIds)
      $.ajax({
        type: 'PUT',
        async: true,
        url: merchClassificationReorderSequenceApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.getStoreInfo(['getMerchClassifications'])
            vm.SweetAlert(res.code, res.message)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 開啟新視窗並前往該頁
    goOtherPage(url) {
      window.open(`${process.env.VUE_APP_ORIGINURL}${url}`, "_blank");
    },
  }
}
</script>
